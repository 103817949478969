import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Slider from "react-slick"

import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

import CTA from "../../components/common/CTA"
import Frame from "../../components/common/frame"
import SVGIcon from "../../components/common/SVGIcon"
import Navigation from "../../components/navigation"
import DropDown from "../../components/utility/dropdown_new"
import Menu from "../../components/utility/menu"
import ArticleCard from "./components/article-card/article-card"
import MediaKit from "./components/media-kit/media-kit"
import "./press-room.scss"

//Constants
export const MENU = {
  NEWS: "Press Room",
  MEDIA_RELEASES: "Media Release",
  MEDIA_KIT: "kit",
}

const GLOBAL_FILTER_COUNT = 12

const PressRoomNew = props => {
  const [showAll, setShowAll] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState(MENU.NEWS)
  const [showAllArticles, setShowAllArticles] = useState(true)
  const [pressCardsList, setPressCardsList] = useState([])
  const [selectedDateObject, setSelectedDateObject] = useState(null)
  const [currentYear, setCurrentYear] = useState()

  const scrollRef = useRef(null)

  var sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  useEffect(() => {
    if (selectedDateObject?.data) {
      const filteredData = getPressCard(
        selectedDateObject.data,
        { value: selectedDateObject.subTitle },
        selectedDateObject.title
      )
      setPressCardsList(filteredData)
    }
  }, [selectedDateObject])

  const openInNewTab = url => {
    window.open(url, "_blank")
  }

  const getYear = dateStr => {
    const year = dateStr.match(/\d{4}/)[0]
    return year
  }

  const getSideMenuData = data => {
    const groupedDataBasedOnPositionYear = {}
    data.forEach(pressItem => {
      const positionYear = getYear(pressItem.position)
      if (!positionYear) return

      if (!groupedDataBasedOnPositionYear[positionYear]) {
        groupedDataBasedOnPositionYear[positionYear] = new Set()
      }

      groupedDataBasedOnPositionYear[positionYear].add(pressItem.order)
    })

    // Sort keys in descending order and store in a Map
    const sortedData = new Map(
      Object.keys(groupedDataBasedOnPositionYear)
        .sort((a, b) => parseInt(b) - parseInt(a)) // Sort keys numerically in descending order
        .map(key => [key, groupedDataBasedOnPositionYear[key]]) // Convert back to key-value pairs
    )

    const menuObject = []
    sortedData.forEach((orders, positionYear) => {
      menuObject.push({
        title: {
          value: positionYear,
          className: "title",
          // action: () => {
          //   scrollTo(positionYear)
          //   setCurrentYear(positionYear)
          // },
        },
        subtitles: Array.from(orders).map(order => ({
          value: order,
          className: "subtitle",
          action: () => {
            scrollTo()
            setCurrentYear(positionYear)
            setShowAllArticles(false)
            setSelectedDateObject({
              data,
              subTitle: order,
              title: positionYear,
            })
            setPressCardsList([])
          },
        })),
      })
    })
    return menuObject
  }

  const getPressCard = (data, subTitle, title, peopleListCount) => {
    const filteredData = data
      .filter(
        pressData =>
          pressData.order === subTitle.value &&
          getYear(pressData.position) === title
      )
      .reverse()
    if (showAllArticles && !showAll) {
      const remainingCount = GLOBAL_FILTER_COUNT - peopleListCount
      if (filteredData.length > remainingCount && remainingCount > 0)
        return filteredData.slice(0, remainingCount)
      else if (remainingCount > 0) return filteredData
      return []
    }
    return filteredData
  }

  const scrollTo = () => {
    window.scroll({
      top: scrollRef.current.offsetTop,
      behavior: "smooth",
    })
  }

  const filteredMenu = data => {
    let resultObject = []

    let menuData = getSideMenuData(data)

    if (currentYear === undefined) {
      setCurrentYear(menuData[0].title.value)
    }

    menuData.map(data => {
      const { title, subtitles } = data
      resultObject.push(title)
      subtitles.map(subtitle => resultObject.push(subtitle))
    })

    return resultObject
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query GetNewsRoomPage {
            SuperOps {
              pages(where: { title: "Press Room 2025" }) {
                title
                heroSection {
                  heading {
                    html
                  }
                }
                newField {
                  ... on SuperOps_Card {
                    cardName
                    title
                    content {
                      html
                    }
                    text
                    cardDescription
                    links
                  }
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }

                ctaBoxes {
                  type
                  theme
                  heading {
                    html
                  }
                  description {
                    html
                  }
                  primaryButtonText
                  primaryButtonLink
                  secondaryButtonText
                  secondaryButtonLink
                }
              }

              peopleList(
                orderBy: createdAt_DESC
                where: {
                  OR: [{ page: "Press Room" }, { page: "Media Release" }]
                }
              ) {
                isFeatured
                position
                order
                company
                name
                title
                page
                picture {
                  url
                }
                linkedInUrl
                order
                thumbnail {
                  url
                }
              }
            }
          }
        `}
        render={data =>
          data.SuperOps.pages.map(page => {
            const { newField, seo, heroSection, ctaBoxes } = page
            const filterPeopleListBasedOnPage = data?.SuperOps?.peopleList?.filter(
              article => article.page === selectedMenu
            )
            let peopleListCount = 0
            const menuData = getSideMenuData(filterPeopleListBasedOnPage)
            return (
              <div className="press-room-main">
                <Frame
                  title={seo?.title}
                  description={seo?.description}
                  keywords={seo?.keywords}
                  ogTitle={seo?.title}
                  ogDescription={seo?.description}
                  ogImage={seo?.image}
                  ogType="website"
                >
                  <header>
                    <Navigation
                      page="Home"
                      dskScroll={400}
                      darkHelloBar
                      theme="dark"
                      darkTheme
                      btn1="secondary white br-47"
                      btn2="primary white ml-9 br-47"
                    />
                  </header>
                  <section className="hero-container">
                    <div className="hero-section d-flex align-items-center text-center flex-column">
                      <div className="title-wrapper">
                        {parse(heroSection?.heading?.html)}
                      </div>
                      <div className="short-red-line"></div>
                    </div>
                    <section className="feature-container">
                      <h2>{newField[0]?.cardName}</h2>
                      <section className="feature-slider">
                        <Slider {...sliderSettings}>
                          {data?.SuperOps?.peopleList
                            .filter(d => d.isFeatured === "Yes")
                            .reverse()
                            .map((data, i) => {
                              const {
                                title,
                                picture,
                                linkedInUrl,
                                position,
                                order,
                              } = data
                              return (
                                <div key={i + title} className="h-100">
                                  <div className="press-feature-card">
                                    <div className="content">
                                      <div className="content-top">
                                        <div className="feature-date-badge">
                                          {`${order} - ${getYear(position)}`}
                                        </div>
                                        <div className="card-heading font-zodiak">
                                          {title}
                                        </div>
                                      </div>
                                      <button
                                        onClick={() =>
                                          openInNewTab(linkedInUrl)
                                        }
                                        className="btn btn-secondary pink"
                                      >
                                        Read More
                                      </button>
                                    </div>
                                    <div className="image">
                                      <img src={picture.url} />
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                        </Slider>
                      </section>
                    </section>
                  </section>

                  <div className="main-container">
                    <div className="btn-wrapper" ref={scrollRef}>
                      <button
                        className="fade-violet"
                        onClick={() => {
                          setShowAllArticles(true)
                          setSelectedMenu(MENU.NEWS)
                          setSelectedDateObject(null)
                        }}
                      >
                        {newField[0]?.title[0]}
                        <SVGIcon
                          name="home/rightArrBlack"
                          className={`right-arrow-black ${
                            selectedMenu === MENU.NEWS ? "show" : "hide"
                          }`}
                        />
                      </button>
                      <button
                        className="fade-pink"
                        onClick={() => {
                          setShowAllArticles(true)
                          setSelectedMenu(MENU.MEDIA_RELEASES)
                          setSelectedDateObject(null)
                        }}
                      >
                        {newField[0]?.title[1]}
                        <SVGIcon
                          name="home/rightArrBlack"
                          className={`right-arrow-black ${
                            selectedMenu === MENU.MEDIA_RELEASES
                              ? "show"
                              : "hide"
                          }`}
                        />
                      </button>
                      <button
                        className="fade-red"
                        onClick={() => setSelectedMenu(MENU.MEDIA_KIT)}
                      >
                        {newField[0]?.title[2]}
                        <SVGIcon
                          name="home/rightArrBlack"
                          className={`right-arrow-black ${
                            selectedMenu === MENU.MEDIA_KIT ? "show" : "hide"
                          }`}
                        />
                      </button>
                    </div>

                    {selectedMenu === MENU.MEDIA_KIT && (
                      <MediaKit details={newField[1]} />
                    )}
                    {(selectedMenu === MENU.NEWS ||
                      selectedMenu === MENU.MEDIA_RELEASES) && (
                      <section className="article-wrapper">
                        <Container>
                          <Row>
                            <Col className="col-3 desktop">
                              <div className="menu">
                                <div className="title">
                                  {selectedMenu === MENU.NEWS
                                    ? newField[0]?.title[0]
                                    : newField[0]?.title[1]}
                                </div>
                                <button
                                  onClick={() => {
                                    setShowAllArticles(true)
                                    setSelectedDateObject(null)
                                  }}
                                  className={`all-menu unstyled-btn ${
                                    showAllArticles ? "active" : ""
                                  }`}
                                >
                                  {newField[0].text[1]}
                                </button>
                                <div className="desktop">
                                  {menuData.map((menu, i) => {
                                    return (
                                      <Menu
                                        data={{
                                          ...menu,
                                          index: i,
                                          activeMenu:
                                            selectedDateObject?.title +
                                            selectedDateObject?.subTitle,
                                        }}
                                      />
                                    )
                                  })}
                                </div>
                              </div>
                            </Col>
                            <Col className="right-container">
                              <div className="mobile-dropdown">
                                <DropDown
                                  currentValue={currentYear}
                                  menuItems={filteredMenu(
                                    filterPeopleListBasedOnPage
                                  )}
                                />
                              </div>
                              {showAllArticles ? (
                                menuData?.map(menu => {
                                  return (
                                    <div key={menu?.title?.value}>
                                      {menu?.subtitles?.map(subTitle => {
                                        const cardsToDisplay = getPressCard(
                                          filterPeopleListBasedOnPage,
                                          subTitle,
                                          menu?.title?.value,
                                          peopleListCount
                                        )
                                        if (cardsToDisplay?.length > 0)
                                          return (
                                            <div key={subTitle?.value}>
                                              <div className="date">
                                                <div className="img-container">
                                                  <img src="https://media.graphassets.com/ykTkzFxQ7K2shgezcM0W" />
                                                </div>
                                                {subTitle?.value} -{" "}
                                                {menu?.title?.value}
                                              </div>
                                              <div className="article-grid">
                                                {cardsToDisplay &&
                                                  cardsToDisplay?.length > 0 &&
                                                  cardsToDisplay?.map(
                                                    (news, index) => {
                                                      peopleListCount++
                                                      return (
                                                        <ArticleCard
                                                          news={news}
                                                          index={index}
                                                        />
                                                      )
                                                    }
                                                  )}
                                              </div>
                                            </div>
                                          )
                                      })}
                                    </div>
                                  )
                                })
                              ) : (
                                <>
                                  <div className="date">
                                    <div className="img-container">
                                      <img src="https://media.graphassets.com/ykTkzFxQ7K2shgezcM0W" />
                                    </div>
                                    {selectedDateObject?.subTitle} -{" "}
                                    {selectedDateObject?.title}
                                  </div>
                                  <div className="article-grid">
                                    {pressCardsList &&
                                      pressCardsList?.length > 0 &&
                                      pressCardsList?.map((news, index) => {
                                        return (
                                          <ArticleCard
                                            news={news}
                                            index={index}
                                          />
                                        )
                                      })}
                                  </div>
                                </>
                              )}
                              {!showAll &&
                                showAllArticles &&
                                filterPeopleListBasedOnPage?.length > 12 && (
                                  <button
                                    className="btn btn-secondary explore-btn"
                                    onClick={() => setShowAll(true)}
                                  >
                                    {newField[0]?.text[2]}
                                  </button>
                                )}
                            </Col>
                          </Row>
                        </Container>
                      </section>
                    )}
                  </div>
                  <section className="cta-black-red-stripes mx-auto container">
                    <CTA
                      data={[ctaBoxes[0]]}
                      lgLeft={6}
                      lgRight={4}
                      newButton
                      isSecondaryBtn
                      newDesign
                    />
                  </section>
                </Frame>
              </div>
            )
          })
        }
      />
    </>
  )
}

export default PressRoomNew
